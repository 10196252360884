<style lang="scss" scoped>
@import "@assets/styles/variables.scss"; //公共样式变量
.content-group {
  height: 100%;
  overflow: hidden;
  .order-log {
    .content-item {
      margin-bottom: 0;
      height: 100%;
    }
    .log-list {
      width: 100%;
      .log-item {
        width: 100%;
        display: flex;
        align-items: flex-start;
        margin-top: 10px;
        border-top: 1px solid #e1e0e0;
        padding-top: 14px;
        &:first-child {
          border-top: none;
          margin-top: 0;
          padding-top: 0;
        }
        .icon {
          width: 40px;
          height: 40px;
          border-radius: 50%;
          background: $color-primary;
          text-align: center;
          line-height: 40px;
          font-size: 24px;
          color: $color-primary-1;
          margin-right: 10px;
          margin-top: 6px;
        }
        .log-data {
          flex-grow: 1;
        }
        .user {
          display: flex;
          align-items: center;
          height: 30px;
          flex-grow: 1;
          .name {
            font-size: 18px;
            font-weight: bold;
            margin-right: auto;
          }
          .time {
            font-size: 14px;
            color: #ccc;
          }
        }
        .status {
          font-size: 16px;
          margin-top: 6px;
          color: #333;
          span {
            color: #f7812b;
          }
        }
      }
    }
  }
  .others,
  .order-log {
    height: 100%;
  }
  .project-company {
    width: 100%;
    height: calc(100% - 155px);
    .content-item:first-child {
      height: calc((100% - 15px) * 0.55);
    }
    .content-item:last-child {
      height: calc((100% - 15px) * 0.45);
    }
    .el-row {
      margin-top: 14px;
      &:first-child {
        margin-top: 0;
      }
    }
  }
  .content-item {
    width: 100%;
    background: #fff;
    border-radius: 4px;
    margin-bottom: 15px;
    .content-title {
      width: 100%;
      height: 46px;
      line-height: 46px;
      box-sizing: border-box;
      padding: 0 15px;
      font-size: 16px;
      color: $color-text-primary;
      font-weight: 400;
      border-bottom: 1px solid $border-color-lighter;
    }
    .content-data {
      width: 100%;
      max-height: calc(100% - 46px);
      overflow: scroll;
      box-sizing: border-box;
      padding: 16px 10px;
    }
    .total-data {
      width: 100%;
      height: 140px;
      margin: 0 !important;
      .el-col {
        height: 100%;
      }
      .total-content {
        width: 100%;
        height: 100%;
        border-radius: 6px;
        box-sizing: border-box;
        padding: 8px 20px;
        &.blue {
          background: linear-gradient(
            25deg,
            rgb(77, 119, 255) 0%,
            rgb(37, 221, 249) 100%
          );
        }
        &.green {
          background: linear-gradient(
            25deg,
            rgb(34, 190, 78) 0%,
            rgb(138, 238, 166) 100%
          );
        }
        &.yellow {
          background: linear-gradient(
            25deg,
            rgb(234, 140, 24) 0%,
            rgb(246, 209, 96) 100%
          );
        }
        &.purple {
          background: linear-gradient(
            25deg,
            rgb(184, 56, 235) 0%,
            rgb(213, 141, 241) 100%
          );
        }
        .total-num {
          font-size: 38px;
          font-weight: bold;
          color: #fff;
          line-height: 50px;
        }
        .total-label {
          font-size: 18px;
          color: #fff;
          line-height: 28px;
        }
      }
    }
    .card-item {
      ::v-deep .el-card__body {
        padding: 10px 20px;
      }
      h2 {
        font-size: 18px;
        line-height: 30px;
      }
      p {
        font-size: 16px;
        line-height: 26px;
        color: #333;
      }
      .order-num {
        margin-top: 10px;
        .num-box {
          text-align: center;
          border: 1px solid #ddd;
          border-radius: 4px;
          padding: 8px 0;
          .label {
            font-size: 16px;
            color: #666;
          }
          .num {
            font-weight: bold;
            font-size: 18px;
            color: #666;
          }
        }
      }
    }
  }
}

.el-tag {
  width: 20px;
  border-radius: 50%;
}
.el-tag--large {
  width: 32px;
}
</style>

<template>
  <section class="page-container">
    <!-- :span 栅格占据的列数..每列为1/24  24/24为100% -->
    <el-row class="content-group" :gutter="20">
      <el-col :span="16" class="others">
        <div class="content-item">
          <el-row class="content-data total-data" :gutter="20">
            <el-col
              :span="6"
              v-for="(item, index) in infoData.work_order_apply"
              :key="index"
            >
              <div class="total-content" :class="item.color">
                <p class="total-num">{{ item.value }}</p>
                <p class="total-label">{{ item.name }}</p>
              </div>
            </el-col>
          </el-row>
        </div>
        <div class="project-company">
          <div class="content-item">
            <h1 class="content-title">项目预览</h1>
            <div class="content-data">
              <el-row
                :gutter="12"
                v-for="(item, index) in infoData.project_list"
                :key="index"
              >
                <el-col :span="8" v-for="project in item" :key="project.id">
                  <el-card shadow="hover" class="card-item">
                    <h2>{{ project.name }}</h2>
                    <p>{{ project.company_name }}</p>
                    <p>{{ project.address }}</p>
                    <!-- <p>项目部主管：{{ project.manager_name || "-" }}</p> -->
                    <el-row :gutter="10" class="order-num">
                      <el-col :span="12">
                        <div class="num-box">
                          <p class="label">未关闭工单数量</p>
                          <p class="num">{{ project.work_order_apply_ing }}</p>
                        </div>
                      </el-col>
                      <el-col :span="12">
                        <div class="num-box">
                          <p class="label">已关闭工单数量</p>
                          <p class="num">{{ project.work_order_apply_done }}</p>
                        </div>
                      </el-col>
                    </el-row>
                  </el-card>
                </el-col>
              </el-row>
            </div>
          </div>
          <div class="content-item">
            <h1 class="content-title">单位预览</h1>
            <div class="content-data">
              <el-row
                :gutter="12"
                v-for="(item, index) in infoData.company_list"
                :key="index"
              >
                <el-col :span="8" v-for="company in item" :key="company.id">
                  <el-card shadow="hover" class="card-item">
                    <h2>{{ company.name }}</h2>
                    <p>{{ company.address }}</p>
                    <el-row :gutter="10" class="order-num">
                      <el-col :span="12">
                        <div class="num-box">
                          <p class="label">项目数量</p>
                          <p class="num">{{ company.project_count }}</p>
                        </div>
                      </el-col>
                      <el-col :span="12">
                        <div class="num-box">
                          <p class="label">职员人数</p>
                          <p class="num">{{ company.user_count }}</p>
                        </div>
                      </el-col>
                    </el-row>
                  </el-card>
                </el-col>
              </el-row>
            </div>
          </div>
        </div>
      </el-col>
      <el-col :span="8" class="order-log">
        <div class="content-item">
          <h1 class="content-title">工单动态</h1>
          <div class="content-data">
            <ul class="log-list">
              <li
                class="log-item"
                v-for="item in infoData.work_order_apply_list"
                :key="item.id"
              >
                <p class="icon">
                  <i class="el-icon-user-solid" />
                </p>
                <div class="log-data">
                  <div class="user">
                    <p class="name">{{ item.issue_user_name }}</p>
                    <p class="time">
                      {{ (item.create_time * 1000) | formatTime }}
                    </p>
                  </div>
                  <p class="status">
                    {{ item.work_order_name }}工单【<span>
                      {{ item.status_name }}</span
                    >】
                  </p>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </el-col>
    </el-row>
  </section>
</template>

<script>
import { $getIndexInfo } from "@api/common";
export default {
  name: "home",
  components: {},
  data() {
    return {
      infoData: {},
    };
  },

  watch: {},

  async created() {
    await this.getMemberMenuList();
    this.getInfo();
  },

  async mounted() {},

  methods: {
    async getInfo() {
      let res = await $getIndexInfo();
      if (res.code != 200) return;
      res.data.company_list = this.$_.chunk(res.data.company_list, 3);
      res.data.project_list = this.$_.chunk(res.data.project_list, 3);
      this.infoData = res.data;
      console.log(res);
    },
  },
};
</script>
